import React, {Component} from 'react';
import { Button } from '@mui/material';
import '../App.css';

import ConstValues from '../utils/ConstValues';
import Preloader from '../components/Preloader';

export default class Wellcome extends Component{

    constructor(){
        super();
        this.state = {
            evaluation: '',
            consentCaducate: true,
            activeConsent: false 
        }
        this.goQuiz = this.goQuiz.bind(this);
    }

    componentDidMount(){
        if(this.props.params.name_id){
            let array_evaluation = this.props.params.name_id.split('-');
            this.getEvaluation(array_evaluation[1]);
        }
    }

    getEvaluation(id){
        fetch(ConstValues.SERVICE_CONNECT+'evaluation/'+id, {
            method: 'GET',
            headers:{
                'Content-Type': 'application/json'
            }
         })
        .then(res => res.json())
        .then((data) => {
            if(data['status'] === 1){
                let evaluation = data['text'];
                this.setState({evaluation});
                this.getConsent(evaluation);
                
            }
        })
        .catch(console.error)
    }

    getConsent(evaluation){
        fetch(ConstValues.SERVICE_CONNECT+'evaluation/consent/'+evaluation.id, {
            method: 'GET',
            headers:{
                'Content-Type': 'application/json'
            }
         })
        .then(res => res.json())
        .then((data) => {
            if(data['status'] === 1){
                this.setState({activeConsent: false})
            }else{
                this.setState({activeConsent: true})
                let actualDate = new Date();
                let consentDate = new Date(evaluation.fecha_consentimiento);
                if(actualDate > consentDate){
                    this.setState({consentCaducate: false})
                }else{
                    this.setState({consentCaducate: true})
                }
            }
        })
        .catch(console.error)
    }

    goQuiz() {
        this.props.navigate('/quiz/'+this.state.evaluation.id);
    }

    render(){
        if(this.state.evaluation){
            let image = ConstValues.IMAGE_COMPANY+this.state.evaluation.id_empresa+'.jpg';
            if(this.state.activeConsent){
                if(this.state.consentCaducate){
                    return(
                        <div className="container-principal">
                            <div style={{textAlign: 'center'}}>
                                <img src={image} alt="photo" height="100"/>
                            </div>
                            <div style={{fontSize: 15, marginBottom: 50, textAlign: 'center'}}>
                                <span style={{fontWeight: '300'}}>Proceso realizado por: </span>
                                <span style={{fontWeight: 'bold', fontSize: 17}}>Grupo </span>
                                <span style={{fontWeight: '300', fontSize: 17}}>Toledo</span>
                            </div>
                            <div className="title-size">Consentimiento</div>
                            <div className="title-size margin-reduce">Estudio de Confiabilidad</div>
            
                            <div style={{marginTop: 35}}>
                                <div className="description">Hola!</div>
                                <div className="description">
                                    Para dar inicio al proceso solicitado por la
                                    empresa <span style={{fontWeight: 'bold'}}>{this.state.evaluation.nombre_empresa}</span>, solicitamos su 
                                    gentil ayuda completando el siguiente 
                                    consentimiento informado.
                                </div>
                                <div className="description">
                                    <span style={{fontWeight: 'bold'}}>Tiempo aproximado: </span> 3 minutos.
                                </div>
                            </div>
                            <div style={{marginTop: 30, marginBottom: 20}}>
                                <Button variant="contained" size='large' className="button-contained" 
                                    onClick={this.goQuiz}
                                >
                                    Comenzar
                                </Button>
                            </div>
                        </div>
                    )
                }else{
                    return (
                        <div className='container-final' style={{textAlign: 'center'}}>
                            <div style={{marginBottom: 40}}>
                                <img src={image} alt="photo" height="100"/>
                            </div>
                            <div style={{fontSize: '2em'}}>SU CONSENTIMIENTO HA CADUCADO</div>
                            <div style={{fontSize: '1.5em'}}>Por favor contactar a +593 99 592 4458 para reactivar su consentimiento.</div>
                        </div>
                    )
                }
            }else{
                return (
                    <div className='container-final' style={{textAlign: 'center'}}>
                        <div style={{marginBottom: 40}}>
                            <img src={image} alt="photo" height="100"/>
                        </div>
                        <div style={{fontSize: '2em'}}>SU CONSENTIMIENTO HA SIDO COMPLETADO.</div>
                    </div>
                )
            }
        }else{
            return (<Preloader/>);
        }
    }
}
