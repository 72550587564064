export default {
    // SERVICE_CONNECT: 'https://consentimiento.grupotoledo.ec/server/api/public/',
    // IMAGE_CONNECT: 'https://consentimiento.grupotoledo.ec/server/api/',
    // IMAGE_COMPANY: 'https://app.grupotoledo.ec/assets/images/',

    // SERVICE_CONNECT: 'http://localhost/quiz-grupo-toledo/server/api/public/',
    // IMAGE_CONNECT: 'http://localhost/quiz-grupo-toledo/server/api/',
    // IMAGE_COMPANY: 'http://localhost/grupo_toledo/client/src/assets/images/',

    // SERVICE_CONNECT: 'http://192.168.238.121/quiz-grupo-toledo/server/api/public/',
    // IMAGE_CONNECT: 'http://192.168.238.121/quiz-grupo-toledo/server/api/',
    // IMAGE_COMPANY: 'http://192.168.238.121/grupo_toledo/client/src/assets/images/',

    SERVICE_CONNECT: 'https://pruebaconsentimiento.grupotoledo.ec/server/api/public/',
    IMAGE_CONNECT: 'https://pruebaconsentimiento.grupotoledo.ec/server/api/',
    IMAGE_COMPANY: 'https://www.grupotoledo.ec/sistema/client/assets/images/',
}